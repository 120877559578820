import api from '..'

import { CartDiscountVoucher } from './carts'

interface Response {
  success: boolean
  message: string
}

interface VoucherResponse extends Response {
  data: CartDiscountVoucher | null
}

export interface CheckDiscountVoucherQueryParams {
  voucher: CartDiscountVoucher
}

export const paymentsNewApi = api.injectEndpoints({
  endpoints: build => ({
    checkDiscountVoucher: build.mutation<
      VoucherResponse,
      CheckDiscountVoucherQueryParams
    >({
      query: params => {
        const { voucher } = params
        return {
          method: 'POST',
          body: voucher,
          url: 'payments/vouchers/check',
        }
      },
    }),
  }),
})

export const { useCheckDiscountVoucherMutation } = paymentsNewApi
