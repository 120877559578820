import api from '..'
import { Association } from './associations'
import { Competition } from './competitions'
import { Address, Participant } from './participants'
import { Team } from './teams'
import { Uniform } from './uniforms'

export interface BaseVoucher {
  amount?: number
  code: string
}

export interface ActiveKidsVoucher extends BaseVoucher {
  balance?: number
  pin: string
  type: 'AK'
}

export interface ActiveKidsVoucherV2 extends BaseVoucher {
  balance?: number
  pin: string
  type: 'AKCK'
}

export type CartDiscountVoucher = ActiveKidsVoucher | ActiveKidsVoucherV2

export interface CartDiscount {
  vouchers: CartDiscountVoucher[]
}

export interface BaseCartParticipantDetails {
  firstName: string
  lastName: string
  dateOfBirth: string
  gender: string
  email: string
  mobile: string
  address: Address
  emergencyContact: string
  emergencyContactNumber: string
}

export interface CartPlayerDetails extends BaseCartParticipantDetails {
  isPlayingAnotherSport?: string
  headshot?: string
}

export interface CartCoachDetails extends BaseCartParticipantDetails {
  headshot?: string
}

export interface CartRefereeDetails extends BaseCartParticipantDetails {
  rugbyReferee?: string
  daysAvailable?: string[]
  headshot?: string
}

export type CartParticipantDetails =
  | CartPlayerDetails
  | CartCoachDetails
  | CartRefereeDetails

export interface CartTeamDetails {
  teamName: string
  primaryContact: string
  primaryEmail: string
  primaryNumber: string
}

export interface CartTeamUniformDesign {
  front: string
  side: string
  back: string
}

export interface CartTotals {
  registration: number
  uniform: number
  discount: number
  subTotalExcTax: number
  tax: number
  subTotalIncTax: number
  processing: number
  total: number
}

export interface CartUniformItem {
  uniform: Uniform
  qty: number
  size: string
  color: {
    front: string
    side: string
    back: string
  }
  custom: {
    number: string
    name: string
    teamName: string
  }
}

export type CartType = 'team' | 'player' | 'coach' | 'referee'

export interface BaseCart {
  _id: string
  userId: string
  association: Association | null
  competition: Competition | null
  discount: CartDiscount | null
  participant: Participant | null
  team: Team | null
  teamDetails: CartTeamDetails | null
  teamUniformDesign: CartTeamUniformDesign | null
  uniform: CartUniformItem[]
  createdAt: Date
  updatedAt: Date
}

export interface TeamCart extends BaseCart {
  type: 'team'
  participantDetails: null
}

export interface PlayerCart extends BaseCart {
  type: 'player'
  participantDetails: CartPlayerDetails | null
}

export interface CoachCart extends BaseCart {
  type: 'coach'
  participantDetails: CartCoachDetails | null
}

export interface RefereeCart extends BaseCart {
  type: 'referee'
  participantDetails: CartRefereeDetails | null
}

export type Cart = TeamCart | PlayerCart | CoachCart | RefereeCart

export interface GetCartParams {
  type: CartType
}

export interface GetCartTotalsParams {
  type: CartType
}

export interface SetQueryParams {
  type: CartType
}

export interface SetCartAssociationQueryParams extends SetQueryParams {
  associationId: string
}

export interface SetCartCompetitionQueryParams extends SetQueryParams {
  competitionId: string
}

export interface SetCartParticipantQueryParams extends SetQueryParams {
  participantId: string
}

export interface SetCartParticipantDetailsQueryParams extends SetQueryParams {
  participantDetails: CartParticipantDetails
}

export interface SetCartTeamQueryParams extends SetQueryParams {
  teamId: string
}

export interface SetCartTeamDetailsQueryParams extends SetQueryParams {
  teamDetails: CartTeamDetails
}

export interface SetCartTeamUniformDesignQueryParams extends SetQueryParams {
  teamUniformDesign: CartTeamUniformDesign
}

export interface AddUniformItemToCartQueryParams extends SetQueryParams {
  uniformItem: CartUniformItem
}

export interface UpdateUniformItemInCartQueryParams extends SetQueryParams {
  uniformItem: CartUniformItem
}

export interface RemoveUniformItemFromCartQueryParams extends SetQueryParams {
  uniformItem: CartUniformItem
}

export interface AddDiscountVoucherToCartQueryParams extends SetQueryParams {
  voucher: CartDiscountVoucher
}

export interface RemoveDiscountVoucherFromCartQueryParams
  extends SetQueryParams {
  voucher: CartDiscountVoucher
}

export interface CreatePaymentIntentForCartQueryParams extends SetQueryParams {}

export interface FinalizeWithoutPaymentQueryParams extends SetQueryParams {}

export interface DeleteCartQueryParams extends SetQueryParams {}

interface Response {
  success: boolean
  message: string
}

interface CartResponse extends Response {
  data: Cart
}

interface CartTotalsResponse extends Response {
  data: CartTotals
}

interface CreatePaymentIntentForCartResponse extends Response {
  data: {
    clientSecret: string
  }
}

interface FinalizeWithoutPaymentResponse extends Response {}

export const cartsApi = api.injectEndpoints({
  endpoints: build => ({
    getCart: build.query<CartResponse, GetCartParams>({
      query: params => {
        const { type } = params
        return {
          url: `carts/${type}`,
        }
      },
      providesTags: ['cart'],
    }),
    getCartTotals: build.query<CartTotalsResponse, GetCartTotalsParams>({
      query: params => {
        const { type } = params
        return {
          url: `carts/${type}/totals`,
        }
      },
      providesTags: ['cart-totals'],
    }),
    setCartAssociation: build.mutation<
      CartResponse,
      SetCartAssociationQueryParams
    >({
      query: params => {
        const { associationId, type } = params
        return {
          method: 'POST',
          body: {},
          url: `carts/${type}/association/${associationId}`,
        }
      },
      invalidatesTags: ['cart'],
    }),
    setCartCompetition: build.mutation<
      CartResponse,
      SetCartCompetitionQueryParams
    >({
      query: params => {
        const { competitionId, type } = params
        return {
          method: 'POST',
          body: {},
          url: `carts/${type}/competition/${competitionId}`,
        }
      },
      invalidatesTags: ['cart', 'cart-totals'],
    }),
    setCartParticipant: build.mutation<
      CartResponse,
      SetCartParticipantQueryParams
    >({
      query: params => {
        const { participantId, type } = params
        return {
          method: 'POST',
          body: {},
          url: `carts/${type}/participant/${participantId}`,
        }
      },
      invalidatesTags: ['cart'],
    }),
    setCartParticipantDetails: build.mutation<
      CartResponse,
      SetCartParticipantDetailsQueryParams
    >({
      query: params => {
        const { participantDetails, type } = params
        return {
          method: 'POST',
          body: participantDetails,
          url: `carts/${type}/participantDetails`,
        }
      },
      invalidatesTags: ['cart'],
    }),
    setCartTeam: build.mutation<CartResponse, SetCartTeamQueryParams>({
      query: params => {
        const { teamId, type } = params
        return {
          method: 'POST',
          body: {},
          url: `carts/${type}/team/${teamId}`,
        }
      },
      invalidatesTags: ['cart'],
    }),
    setCartTeamDetails: build.mutation<
      CartResponse,
      SetCartTeamDetailsQueryParams
    >({
      query: params => {
        const { teamDetails, type } = params
        return {
          method: 'POST',
          body: teamDetails,
          url: `carts/${type}/teamDetails`,
        }
      },
      invalidatesTags: ['cart'],
    }),
    setCartTeamUniformDesign: build.mutation<
      CartResponse,
      SetCartTeamUniformDesignQueryParams
    >({
      query: params => {
        const { teamUniformDesign, type } = params
        return {
          method: 'POST',
          body: teamUniformDesign,
          url: `carts/${type}/teamUniformDesign`,
        }
      },
      invalidatesTags: ['cart'],
    }),

    addUniformItemToCart: build.mutation<
      CartResponse,
      AddUniformItemToCartQueryParams
    >({
      query: params => {
        const { uniformItem, type } = params
        return {
          method: 'POST',
          body: uniformItem,
          url: `carts/${type}/uniform/add`,
        }
      },
      invalidatesTags: ['cart', 'cart-totals'],
    }),

    updateUniformItemInCart: build.mutation<
      CartResponse,
      UpdateUniformItemInCartQueryParams
    >({
      query: params => {
        const { uniformItem, type } = params
        return {
          method: 'POST',
          body: uniformItem,
          url: `carts/${type}/uniform/update`,
        }
      },
      invalidatesTags: ['cart', 'cart-totals'],
    }),

    removeUniformItemFromCart: build.mutation<
      CartResponse,
      RemoveUniformItemFromCartQueryParams
    >({
      query: params => {
        const { uniformItem, type } = params
        return {
          method: 'POST',
          body: uniformItem,
          url: `carts/${type}/uniform/remove`,
        }
      },
      invalidatesTags: ['cart', 'cart-totals'],
    }),

    addDiscountVoucherToCart: build.mutation<
      CartResponse,
      AddDiscountVoucherToCartQueryParams
    >({
      query: params => {
        const { voucher, type } = params
        return {
          method: 'POST',
          body: voucher,
          url: `carts/${type}/discount/vouchers/add`,
        }
      },
      invalidatesTags: ['cart', 'cart-totals'],
    }),

    removeDiscountVoucherFromCart: build.mutation<
      CartResponse,
      RemoveDiscountVoucherFromCartQueryParams
    >({
      query: params => {
        const { voucher, type } = params
        return {
          method: 'POST',
          body: voucher,
          url: `carts/${type}/discount/vouchers/remove`,
        }
      },
      invalidatesTags: ['cart', 'cart-totals'],
    }),

    createPaymentIntentForCart: build.mutation<
      CreatePaymentIntentForCartResponse,
      CreatePaymentIntentForCartQueryParams
    >({
      query: params => {
        const { type } = params
        return {
          method: 'POST',
          url: `carts/${type}/payment-intents`,
        }
      },
    }),

    finalizeWithoutPayment: build.mutation<
      FinalizeWithoutPaymentResponse,
      FinalizeWithoutPaymentQueryParams
    >({
      query: params => {
        const { type } = params
        return {
          method: 'POST',
          url: `carts/${type}/finalize`,
        }
      },
    }),

    deleteCart: build.mutation<null, DeleteCartQueryParams>({
      query: params => {
        const { type } = params
        return {
          method: 'DELETE',
          url: `carts/${type}`,
        }
      },
    }),
  }),
})

export const {
  useGetCartQuery,
  useGetCartTotalsQuery,
  useSetCartAssociationMutation,
  useSetCartCompetitionMutation,
  useSetCartParticipantMutation,
  useSetCartParticipantDetailsMutation,
  useSetCartTeamMutation,
  useSetCartTeamDetailsMutation,
  useSetCartTeamUniformDesignMutation,
  useAddUniformItemToCartMutation,
  useUpdateUniformItemInCartMutation,
  useRemoveUniformItemFromCartMutation,
  useAddDiscountVoucherToCartMutation,
  useRemoveDiscountVoucherFromCartMutation,
  useCreatePaymentIntentForCartMutation,
  useFinalizeWithoutPaymentMutation,
  useDeleteCartMutation,
} = cartsApi
