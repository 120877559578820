import {
  CartType,
  useGetCartQuery,
  useSetCartAssociationMutation,
  useSetCartCompetitionMutation,
  useSetCartParticipantMutation,
  useSetCartTeamMutation,
  useSetCartTeamDetailsMutation,
  useSetCartTeamUniformDesignMutation,
  useUpdateUniformItemInCartMutation,
  useAddUniformItemToCartMutation,
  useRemoveUniformItemFromCartMutation,
  useSetCartParticipantDetailsMutation,
  useAddDiscountVoucherToCartMutation,
  useRemoveDiscountVoucherFromCartMutation,
  useGetCartTotalsQuery,
  useCreatePaymentIntentForCartMutation,
  useFinalizeWithoutPaymentMutation,
  useDeleteCartMutation,
} from '../services/api/endpoints/carts'
import { useCheckDiscountVoucherMutation } from '../services/api/endpoints/paymentsNew'

interface UseCartProps {
  type: CartType
}

const convertToCurrency = (amount: number): string => {
  return amount.toFixed(2)
}

export const useCart = ({ type }: UseCartProps) => {
  const { data: cart, isLoading: isCartLoading } = useGetCartQuery({ type })
  const { data: totals, isLoading: isTotalsLoading } = useGetCartTotalsQuery({
    type,
  })

  const [setAssociation] = useSetCartAssociationMutation()
  const [setCompetition] = useSetCartCompetitionMutation()
  const [setParticipant] = useSetCartParticipantMutation()
  const [setParticipantDetails] = useSetCartParticipantDetailsMutation()
  const [setTeam] = useSetCartTeamMutation()
  const [setTeamDetails] = useSetCartTeamDetailsMutation()
  const [setTeamUniformDesign] = useSetCartTeamUniformDesignMutation()
  const [addUniformItemToCart] = useAddUniformItemToCartMutation()
  const [removeUniformItemFromCart] = useRemoveUniformItemFromCartMutation()
  const [updateUniformItemInCart] = useUpdateUniformItemInCartMutation()
  const [addDiscountVoucherToCart] = useAddDiscountVoucherToCartMutation()
  const [removeDiscountVoucherFromCart] =
    useRemoveDiscountVoucherFromCartMutation()
  const [createPaymentIntent] = useCreatePaymentIntentForCartMutation()
  const [finalizeWithoutPayment] = useFinalizeWithoutPaymentMutation()
  const [deleteCart] = useDeleteCartMutation()
  const [checkDiscountVoucher] = useCheckDiscountVoucherMutation()

  const getDiscountVoucher = (voucherType: string) => {
    const foundVoucher = (cart?.data?.discount?.vouchers || []).find(
      voucher => voucher.type === voucherType,
    )

    return foundVoucher ?? null
  }

  const hasDiscountVoucher = (voucherType: string) => {
    return !!getDiscountVoucher(voucherType)
  }

  return {
    cart,
    isCartLoading,
    isTotalsLoading,
    setAssociation,
    setCompetition,
    setParticipant,
    setParticipantDetails,
    setTeam,
    setTeamDetails,
    setTeamUniformDesign,
    addUniformItemToCart,
    removeUniformItemFromCart,
    updateUniformItemInCart,
    addDiscountVoucherToCart,
    removeDiscountVoucherFromCart,
    createPaymentIntent,
    finalizeWithoutPayment,
    checkDiscountVoucher,
    getDiscountVoucher,
    hasDiscountVoucher,
    deleteCart,
    totalsNumeric: totals?.data,
    totals: {
      registration: convertToCurrency(totals?.data?.registration || 0),
      uniform: convertToCurrency(totals?.data?.uniform || 0),
      discount: convertToCurrency(totals?.data?.discount || 0),
      subTotalExcTax: convertToCurrency(totals?.data?.subTotalExcTax || 0),
      tax: convertToCurrency(totals?.data?.tax || 0),
      subTotalIncTax: convertToCurrency(totals?.data?.subTotalIncTax || 0),
      processing: convertToCurrency(totals?.data?.processing || 0),
      total: convertToCurrency(totals?.data?.total || 0),
    },
  }
}
